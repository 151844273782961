.navigation {
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 20px;
  width: 100%;
  padding: 20px;
  background: var(--teal-color-fade);
}

.navigation__icon {
  position: fixed;
  z-index: 2;
  cursor: pointer;
  height: 20px;
}

.navigation__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-left: 40px;
  background: initial;
}

.navigation__menu {
  position: fixed;
  z-index: 3;
  display: flex;
  justify-content: space-evenly;
  width: 450px;
  padding-left: 5px;
}

.navigation__link {
  text-decoration: none;
  color: var(--neutral-color);
  font-weight: 800;
  padding: 10px 5px;
}

.navigation__link:hover,
.navigation__link:focus,
.navigation__link:active,
.active {
  color: var(--orange-color);
}

.navigation__link:hover,
.navigation__link:focus{
  text-decoration: underline;
  color: var(--orange-color);
}

@media screen and (max-width: 490px) {
  .navigation-mobile-open {
    height: 30%;
  }
  .navigation__modal {
    padding-top: 50px;
    padding-left: 20px;
  }
  .navigation__menu {
    flex-direction: column;
    height: 25%;
    width: 120px;
    padding-left: 0;
  }

  .navigation__link {
    padding: 5px 2.5px;
  }
}