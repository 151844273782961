@import "./App.css";
@import "./components/Footer/Footer.css";
@import "./components/Main/Main.css";
@import "./components/Navigation/Navigation.css";
@import "./components/content.css";
@import "./views/HomePage/Home.css";
@import "./views/Personal/Personal.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d1d7c9;
  --teal-color: #1a3139;
  --teal-color-fade: #1a3139ad;
  --teal-color-fade-x: #1a313917;
  --neutral-color: #d1d7c9;
  --orange-color: #d5621f;
}

html {
  scroll-behavior: smooth;
}

h2 {
  color: var(--orange-color);
}

a {
  text-decoration: none;
  color: inherit;
}
