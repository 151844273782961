.home__body {
  text-align: justify;
}

.home__social {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 8px;
}

.home__social-icon {
  width: 40px;
}

.home__social-icon:hover {
  filter: invert(20%);
}
