.content-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--teal-color);
  min-height: 75vh;
}

.content-page__title {
  padding-top: 80px;
}

.content-page__body {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 500px;
  padding: 20px;
  line-height: 1.75;
  text-align: center;
  border: solid .5px var(--orange-color);
}

@media screen and (max-width: 580px) {
  .content-page__body {
    border: unset;
  }
}

.link {
  padding: 10px;
  text-decoration: none;
  color: var(--teal-color);
}

.link:hover {
  background-color: var(--teal-color-fade-x);
  color: var(--orange-color);
}
